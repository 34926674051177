import React from "react";

const PaymentsRefunds = (props) => {
  const options = [
    {
      text: 'If I make a payment using KONEK, how will I receive a refund if I return an item?',
      handler: props.actionProvider.handlePaymentsRefunds1,
      id: 1,
    },
    {
      text: 'If I made a payment using KONEK and selected the wrong bank account, how can I cancel this order?',
      handler: props.actionProvider.handlePaymentsRefunds2,
      id: 2
    },
    {
      text: 'I\'m not sure if my payment was completed, how do I confirm?',
      handler: props.actionProvider.handlePaymentsRefunds3,
      id: 3
    },
    {
      text: 'Something went wrong with my KONEK transaction, who do I contact?',
      handler: props.actionProvider.handlePaymentsRefunds4,
      id: 4
    },
    {
      text: 'How is my information kept safe using KONEK?',
      handler: props.actionProvider.handlePaymentsRefunds5,
      id: 5
    },
    {
      text: 'Does the merchant have access to my banking information?',
      handler: props.actionProvider.handlePaymentsRefunds6,
      id: 6
    },
    {
      text: 'How do I help protect against fraud when using KONEK?',
      handler: props.actionProvider.handlePaymentsRefunds7,
      id: 7
    },
  ];

  const optionsMarkup = options.map((option) => (
    <label key={option.text.replace(/\s+/g, '-').toLowerCase()}>
    <input
      type="radio"
      className='consumer-product-button'
      value={option.id}
      name="consumer"
      onClick={option.handler}
    />
    
    <span className={option.text.replace(/\s+/g, '-').toLowerCase()}>{option.text}</span>
      </label>
  ));

  return <div className='consumer-products-container'>{optionsMarkup}</div>;
};

export default PaymentsRefunds;