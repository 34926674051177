class MessageParser {
    constructor(actionProvider) {
      this.actionProvider = actionProvider;
    }
  
    parse(message) {
      const lowerCaseMessage = message.toLowerCase();

      if (
          lowerCaseMessage.includes('hello') ||
          lowerCaseMessage.includes('hi') ||
          lowerCaseMessage.includes('hey') ||
          lowerCaseMessage.includes('yo') ||
          lowerCaseMessage.includes('heya')
      ){
        this.actionProvider.handleGreet();
      }
      
  
      // if (lowerCaseMessage.includes('e-Transfer?')) {
      //   this.actionProvider.WhatIseTransfer();
      // }

      // carrers
      else if (['how do i apply for a job', 'career', 'apply for', 'job', 'jobs', 'hiring', 'careers'].some(el => lowerCaseMessage.includes(el)) ) {
        this.actionProvider.careers();
    }

    // contact
    else if (['contact', 'human', 'phone', 'contact you', 'contact us', 'agent'].some(el => lowerCaseMessage.includes(el)) ) {
      this.actionProvider.contactUs();
  }
    else {
     this.actionProvider.tryAgain();
    }
  }
  }
  
  export default MessageParser;