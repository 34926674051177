import React from "react";

const GeneralQuestions = (props) => {
  const options = [
    {
      text: 'What is KONEK?',
      handler: props.actionProvider.handleGeneral1,
      id: 1,
    },
    {
      text: 'Who runs the KONEK service?',
      handler: props.actionProvider.handleGeneral2,
      id: 2
    },
    {
      text: 'Where can I pay with KONEK?',
      handler: props.actionProvider.handleGeneral3,
      id: 3
    },
    {
      text: 'What is beta testing?',
      handler: props.actionProvider.handleGeneral4,
      id: 4
    },
    {
      text: 'Which financial institutions are offering the KONEK service?',
      handler: props.actionProvider.handleGeneral5,
      id: 5
    },
    {
      text: 'How can I sign up to participate in the KONEK beta testing?',
      handler: props.actionProvider.handleGeneral6,
      id: 6
    },
    {
      text: 'I am a business owner and I want to learn more about taking part in KONEK beta testing, who can I contact?',
      handler: props.actionProvider.handleGeneral7,
      id: 7
    },
  ];

  const optionsMarkup = options.map((option) => (
    <label key={option.text.replace(/\s+/g, '-').toLowerCase()}>
    <input
      type="radio"
      className='consumer-product-button'
      value={option.id}
      name="consumer"
      onClick={option.handler}
    />
    
    <span className={option.text.replace(/\s+/g, '-').toLowerCase()}>{option.text}</span>
      </label>
  ));

  return <div className='consumer-products-container'>{optionsMarkup}</div>;
};

export default GeneralQuestions;