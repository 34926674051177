import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import InteracBotAvatar from "./components/InteracBotAvatar/InteracBotAvatar";
import KonekLogo from "./components/KonekLogo/KonekLogo";
import LearningOptions from "./components/LevelOne/LevelOne";
import MoreQuestions from "./components/MoreQuestions/MoreQuestions";
import Feedback from "./components/Feedback/Feedback";

import GeneralQuestions from "./components/GeneralQuestions/GeneralQuestions";
import FunctionalityQuestions from "./components/FunctionalityQuestions/FunctionalityQuestions";
import PaymentsRefunds from "./components/PaymentsRefunds/PaymentsRefunds";
const config = {
  botName:  "Konek Help",
  initialMessages: [
    createChatBotMessage("Hi there How can we help?"),
    createChatBotMessage("Please select one of the options below.",
    {
      widget: "learningOptions",
    }),
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#000000",
    },
    chatButton: {
      backgroundColor: "#CA27CA",
    },
  },
  customComponents: {
    // Replaces the default header
   header: () => <div className="interacAvatar-header"><KonekLogo /></div>,
   botAvatar: (props) => <InteracBotAvatar {...props} />,
  },
  widgets: [
    {
      widgetName: "learningOptions",
      widgetFunc: (props) => <LearningOptions {...props} />,
    },

    {
      widgetName: "MoreQuestions",
      widgetFunc: (props) => <MoreQuestions {...props} />,
    },
    {
      widgetName: "Feedback",
      widgetFunc: (props) => <Feedback {...props} />,
    },
    {
      widgetName: "GeneralQuestions",
      widgetFunc: (props) => <GeneralQuestions {...props} />,
    },
    {
      widgetName: "FunctionalityQuestions",
      widgetFunc: (props) => <FunctionalityQuestions {...props} />,
    },
    {
      widgetName: "PaymentsRefunds",
      widgetFunc: (props) => <PaymentsRefunds {...props} />,
    },
  ],
};

export default config;
