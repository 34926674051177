import React from "react";

const FunctionalityQuestions = (props) => {
  const options = [
    {
      text: 'I have received a unique access code from my financial institution. How can I transact with KONEK as part of the beta test?',
      handler: props.actionProvider.handleFunc1,
      id: 1,
    },
  ];

  const optionsMarkup = options.map((option) => (
    <label key={option.text.replace(/\s+/g, '-').toLowerCase()}>
    <input
      type="radio"
      className='consumer-product-button'
      value={option.id}
      name="consumer"
      onClick={option.handler}
    />
    
    <span className={option.text.replace(/\s+/g, '-').toLowerCase()}>{option.text}</span>
      </label>
  ));

  return <div className='consumer-products-container'>{optionsMarkup}</div>;
};

export default FunctionalityQuestions;