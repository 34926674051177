import React from "react";

import { ReactComponent as KonekIcon } from "./logo.svg";

const KonekLogo = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        <KonekIcon className="react-chatbot-kit-chat-bot-avatar-icon" />
      </div>
    </div>
  );
};

export default KonekLogo;