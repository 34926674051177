import parse from 'html-react-parser'
class ActionProvider {
    constructor(createChatBotMessage, setStateFunc) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
    }
    
    greet() {
        // const greetingMessage = this.createChatBotMessage('Hi, friend.');
        // this.updateChatbotState(greetingMessage);

        const greetingMessage = this.createChatBotMessage('Hi there How can we help?');
        this.updateChatbotState(greetingMessage);
    }

    careers() {
      const careersMessage = this.createChatBotMessage(parse('Power your future with a career at Interac. <a href="https://www.interac.ca/en/about/careers/">Learn</a> about open positions.'));
      this.updateChatbotState(careersMessage);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    }

    contactUs() {
      const contactUs = this.createChatBotMessage(parse('Visit our <a href="https://www.interac.ca/en/contact-us/">contact us</a> page for details.'));
      this.updateChatbotState(contactUs);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    }


    WhatIseTransfer() {
      const WhatIseTransferMessage = this.createChatBotMessage(parse('<em>Interac</em> e-Transfer is a fast, secure and convenient way to send money to anyone in Canada using online banking. The participating bank or credit union transfers the funds using established and secure banking procedures. Transfers are almost instant, but can take up to 30 minutes depending on your bank or credit union.'));
      this.updateChatbotState(WhatIseTransferMessage);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    }

  eTransferContact() {
    const eTransferContactMessage = this.createChatBotMessage(parse('Please contact your bank or credit union to troubleshoot any transactions in progress. The contact information is usually on the back of your <em>Interac</em> debit card. You can also use this <em>Interac</em> e-Transfer form.'));
    this.updateChatbotState(eTransferContactMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  eTransferHowToSend() {
    const eTransferHowToSend = this.createChatBotMessage(parse('Exact steps vary by individual bank or credit union, but the process will always be simple. Just log into your online bank account, navigate to the <em>Interac</em> e-Transfer section and follow the instructions.'));
    this.updateChatbotState(eTransferHowToSend);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  eTransferHowToCancel() {
    const eTransferHowToCancel = this.createChatBotMessage(parse('To cancel a transfer, log into your online bank account. Go to the transaction or payment history and select the <em>Interac</em> e-Transfer transaction you wish to cancel. Use the cancel option provided. This process varies by financial institution, who may also charge a cancellation fee. Contact your bank or credit union for more information.'));
    this.updateChatbotState(eTransferHowToCancel);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  HowToDeposit() {
    const HowToDepositMessage = this.createChatBotMessage(parse('When someone sends you money via <em>Interac</em> e-Transfer, you will receive an email or text message that a transfer has been sent to you. The notification includes information such as Sender\'s name, Transfer amount A link to deposit your transfer to your bank account. The fastest way to get your funds is through your financial institution’s online or mobile banking service. Simply click the link in the notification, select your bank or credit union, login to online or mobile banking and deposit the money into the account of your choice. If you cannot click on the link, either cut and paste or type the link into your browser. You will be prompted to enter the answer to a security question set by the sender, as part of the secure login process. Alternatively, Autodeposit is an <em>Interac</em> e-Transfer feature that allows users to register to have incoming <em>Interac</em> e–Transfer funds deposited directly into their bank account – no security question and answer needed. Learn more about <em>Interac</em> e-Transfer enhanced features below.'));
    this.updateChatbotState(HowToDepositMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  HowToSecureEtransfer() {
    const HowToSecureEtransferMessage = this.createChatBotMessage('You are not sending money by email or text message: Only the notification travels over the Internet to advise the recipient that the transfer has been initiated and to provide instructions on how to deposit the money. The money always resides safely at a financial institution and is transferred through existing payment networks that banks and credit unions have used for years to settle cheques, bank machine deposits and withdrawals. Security measures have been built into the system, including: Encryption technology, Confidential user IDs and passwords issued by financial institutions, Secure login process, A security question and answer to ensure only the intended recipient receives the money, 128-bit browser encryption.');
    this.updateChatbotState(HowToSecureEtransferMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  etransferCost() {
    const etransferCostMessage = this.createChatBotMessage(parse('There may be a fee from your financial institution for using the <em>Interac</em> e-Transfer service. Please check with your bank or credit union directly.'));
    this.updateChatbotState(etransferCostMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  etransferLimit() {
    const etransferLimitMessage = this.createChatBotMessage('Like most financial transactions, there are limits on the amount you can send. Each financial institution sets their own. Please contact your bank or credit union for details.');
    this.updateChatbotState(etransferLimitMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  etransferStopTxt() {
    const etransferStopTxtMessage = this.createChatBotMessage(parse('You received a text notification because the sender selected “text message” to send you money via <em>Interac</em> e-Transfer. We recommend you provide the sender with your email address instead for sending. To permanently stop all text notifications from <em>Interac</em> e-Transfer, reply to an <em>Interac</em> e-Transfer text with the message STOP (or text STOP to 100001). Please note: Once stopped, text message notifications cannot be reinstated for all transactions moving forward. Standard text messaging rates apply.'));
    this.updateChatbotState(etransferStopTxtMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  etransferNonCanadian() {
    const etransferNonCanadianMessage = this.createChatBotMessage('If you have received an <em>Interac</em> e-Transfer and do not have an account with a participating Canadian bank, ask your sender to cancel the transfer. Once the transfer is cancelled, the funds will be returned to the sender’s account. If you are unable to contact your sender, the transfer will expire in approximately 30 days. Once the transfer expires, the money is automatically deposited into the sender’s account.');
    this.updateChatbotState(etransferNonCanadianMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  etransferNoBank() {
    const etransferNoBankMessage = this.createChatBotMessage(parse('If your bank or credit union doesn’t offer <em>Interac</em> e-Transfer, you cannot receive funds. Please contact your bank or financial institution for additional information.'));
    this.updateChatbotState(etransferNoBankMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  etransferSecurityQA() {
    const etransferSecurityQAMessage = this.createChatBotMessage(parse('If you don’t know the answer to the security question, contact the sender. If you answer the security question incorrectly more than three times, the <em>Interac</em> e-Transfer transaction will automatically cancel.'));
    this.updateChatbotState(etransferSecurityQAMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  whatisAutodeposit() {
    const whatisAutodeposit = this.createChatBotMessage(parse('Autodeposit is an <em>Interac</em> e-Transfer feature that allows users to register to have incoming <em>Interac</em> e–Transfer funds deposited directly into their bank account – no security question and answer needed. <a href="https://www.interac.ca/en/consumers/products/interac-e-transfer/enhanced-features/#fi-list-autodeposit" target="_blank">Click here</a> for a list of participating banks and credit unions, and to learn more about the <em>Interac</em> e-Transfer Autodeposit feature.'));
    this.updateChatbotState(whatisAutodeposit);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  DebitWorks() {
    const DebitWorksMessage = this.createChatBotMessage(parse('<em>Interac</em> Debit and <em>Interac</em> Contactless transactions are approved by your bank or credit union through secure online systems in real-time. Transactions appear instantly on your bank account records, allowing you to monitor your available balance so you can keep track of your spending.'));
    this.updateChatbotState(DebitWorksMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }

  DebitSecurity() {
    const DebitSecurityMessage = this.createChatBotMessage(parse('<em>Interac</em> Debit and <em>Interac</em> Flash are extremely secure forms of payment with sophisticated safeguards and fraud detection tools in place to protect you: <em>Interac</em> debit cards use EMV secure chip processing to store and process data securely. This makes it next to impossible to create counterfeit cards and protects you against fraud tactics such as skimming, transaction replay and electronic pickpocketing. When you use <em>Interac</em> Debit on your mobile device, you enjoy the same security features as your <em>Interac</em> debit card, as well as your device’s standard security measures such as passcode or biometric ID verification. Transaction limits are set at $100 per transaction.  There is also added protection of a cumulative contactless limit set by individual banks or credit unions.  A cumulative limit is the maximum contactless spend limit that can be done by an individual <em>Interac</em> debit card before the user is promoted to reset the limit with a Chip and PIN transaction. This confirms that you are the authorized cardholder. You are also protected from any losses due to unauthorized transactions under the <em>Interac</em> Zero Liability Policy. If your card is lost or stolen, contact your bank or credit union right away.'));
    this.updateChatbotState(DebitSecurityMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  ApplePayDebit() {
    const ApplePayDebitMessage = this.createChatBotMessage(parse('<em>Interac</em> Debit on Apple Pay is a new way to make debit payments using <em>Interac</em> Debit on your eligible Apple device.<em>Interac</em> Debit for In-App & In-Browser payments is the easy, fast, and secure way for Canadians to pay directly from their bank account when shopping online using their mobile device. An in-app payment takes place within the merchant’s native mobile app. An in-browser payment takes place within a supported mobile browser (i.e Safari).'));
    this.updateChatbotState(ApplePayDebitMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  ApplePay() {
    const ApplePayMessage = this.createChatBotMessage(parse('<em>Interac</em> Debit cardholders with a debit card issued from participating financial institutions can use <em>Interac</em> Debit to pay with Apple Pay at merchants who accept <em>Interac</em> Flash contactless payments. For in-app and in-browser transactions, <em>Interac</em> debit cardholders with a debit card issued from participating financial institutions can pay on select merchant applications or mobile websites using Apple Pay. We are continuously working to onboard more issuers and merchants to support in-app and in-browser with <em>Interac</em> Debit.'));
    this.updateChatbotState(ApplePayMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  requestMoney() {
    const requestMoneyMessage = this.createChatBotMessage(parse('Request Money is a feature that allows you to send a request for funds via the <em>Interac</em> e-Transfer system. To learn more about <em>Interac</em> e-Transfer, click here. Once the request is fulfilled and the funds are available in your account, you will receive a notification via text or email.'));
    this.updateChatbotState(requestMoneyMessage);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  whatisOpenPayment() {
    const whatisOpenPayment = this.createChatBotMessage(parse('Transit Open Payments or open payment fare systems provide the ability for transit riders to tap a payment card or a mobile wallet at fare readers without the need to use a transit fare card or transit app to pay for their fare. Interac is enabling the ability for a debit card or mobile wallet supported by <em>Interac</em><sup>&reg;</sup> Debit to be used for this purpose.'));
    this.updateChatbotState(whatisOpenPayment);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  whereCanIUseDebitonTransit() {
    const whereCanIUseDebitonTransit = this.createChatBotMessage(parse('The ability to pay for transit directly via Interac Debit is available by Metrolinx on the UP Express in Toronto and TransLink in Vancouver.Additional transit authorities across the GTHA have recently been added including GO Transit, Brampton Transit, Burlington Transit, Durham Region Transit, Hamilton Street Railway, MiWay in Mississauga, Oakville Transit, York Region Transit, Para Transpo in Ottawa and more to come.'));
    this.updateChatbotState(whereCanIUseDebitonTransit);

    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
  }
  tryAgain() {
    const tryAgainMessage = this.createChatBotMessage(parse('Sorry I\'m still learning and didn\'t understand your question. Try using different key words, or <a href="https://www.interac.ca/en/contact-us/" target="_blank">Contact Us</a> for further assistance.'));
    this.updateChatbotState(tryAgainMessage);
  }


  moreQuestions () {
    const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
      {
        widget: 'learningOptions',
      }
    );
    this.updateChatbotState(moreQuestionsMessage);
  }

  handleGreet = () => {
    const message = this.createChatBotMessage(
      'Hi there! You can use this tool to help you find answers to your questions about Interac products and services. Please select one of the options below.',
      {
        widget: 'learningOptions',
      }
    );

    this.updateChatbotState(message);
  };

  handleGeneralQuestions = () => {
      const message = this.createChatBotMessage(
        'How can we help?',
        {
          widget: 'GeneralQuestions',
        }
      );
  
      this.updateChatbotState(message);
    };

    handleFunctionalityQuestions = () => {
      const message = this.createChatBotMessage(
        'How can we help?',
        {
          widget: 'FunctionalityQuestions',
        }
      );
  
      this.updateChatbotState(message);
    };
    handlePaymentsRefunds = () => {
      const message = this.createChatBotMessage(
        'How can we help?',
        {
          widget: 'PaymentsRefunds',
        }
      );
  
      this.updateChatbotState(message);
    };

    handleGeneral1 = () => {
      const handleGeneral1 = this.createChatBotMessage(parse('The KONEK payment solution, which is currently in beta testing by invitation only, is a brand-new, made-in-Canada online checkout option that will give Canadians more choice and control over how they securely transact online at participating merchants. KONEK has been developed by Interac Corp. In collaboration with Canada’s leading financial institutions.  More information about KONEK will be available closer to its commercial launch.'));
      this.updateChatbotState(handleGeneral1);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handleGeneral2 = () => {
      const handleGeneral2 = this.createChatBotMessage(parse('KONEK, which is currently in beta testing by invitation only, is supported by Canada’s leading financial institutions and powered by Interac Corp.'));
      this.updateChatbotState(handleGeneral2);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handleGeneral3 = () => {
      const handleGeneral3 = this.createChatBotMessage(parse('Exact steps vary by individual bank or credit union, but the process will always be simple. Just log into your online bank account, navigate to the <em>Interac</em> e-Transfer section and follow the instructions.'));
      this.updateChatbotState(handleGeneral3);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handleGeneral4 = () => {
      const handleGeneral4 = this.createChatBotMessage(parse('Beta testing is an opportunity for users to experience a product in a production environment to help uncover any bugs or issues before the service is made available more broadly. The KONEK service is currently in beta testing by invitation only. More information about KONEK will be available closer to its full release.'));
      this.updateChatbotState(handleGeneral4);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handleGeneral5 = () => {
      const handleGeneral5 = this.createChatBotMessage(parse('The KONEK service is currently in beta testing by invitation only through RBC, TD, National Bank and Scotiabank.  More information about KONEK will be available closer to its commercial launch.'));
      this.updateChatbotState(handleGeneral5);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handleGeneral6 = () => {
      const handleGeneral6 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. Keep an eye on KONEK.ca as we get closer to its commercial launch.'));
      this.updateChatbotState(handleGeneral6);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handleGeneral7 = () => {
      const handleGeneral7 = this.createChatBotMessage(parse('The KONEK service is currently in beta testing with a small number of merchants. You can speak with your acquirer directly to learn more about KONEK, or complete this intake form to learn more.'));
      this.updateChatbotState(handleGeneral7);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handleFunc1 = () => {
      const handleFunc1 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. If you are part of the beta testing pilot, you can use it to process transactions at the following merchants (INSERT HERE).  The first time you use KONEK, you simply select KONEK at checkout, select your financial institution from the list, sign-in to your online banking and provide consent, pick your preferred payment method accepted by the merchant and complete your purchase. If you choose to create a profile, the next time you choose KONEK on the same device, your preferred payment information will be saved to your profile.'));
      this.updateChatbotState(handleFunc1);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handlePaymentsRefunds1 = () => {
      const handlePaymentsRefunds1 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. If you are part of the beta testing pilot, and need to process a refund, it is processed in a similar way to the original form of payment. Once you process a return with the merchant, you will be refunded via the same payment method you selected from your financial institution at time of payment.  Refund policies do vary across merchants, if you have any questions about refunds, please contact the merchant directly.'));
      this.updateChatbotState(handlePaymentsRefunds1);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };

    handlePaymentsRefunds2 = () => {
      const handlePaymentsRefunds2 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. If you are part of the beta testing pilot and have any questions about your order and how to cancel it, please contact the merchant directly. '));
      this.updateChatbotState(handlePaymentsRefunds2);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };

    handlePaymentsRefunds3 = () => {
      const handlePaymentsRefunds3 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. If you are part of the beta testing pilot, upon selecting your payment type from the list and once the payment has been approved by your financial institution, you should see an order confirmation screen from the merchant.  If you have any questions or concerns regarding order or payment confirmation, please contact the merchant. '));
      this.updateChatbotState(handlePaymentsRefunds3);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handlePaymentsRefunds4 = () => {
      const handlePaymentsRefunds4 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. If you are part of the beta test and have a question about your online purchase (e.g., delivery time, shipping options, product information, etc.), please contact the merchant.<br /><br />If you have questions or concerns about your payment using the KONEK service (e.g., limits, charges to your accounts, processing times, etc.) that cannot be answered by the merchant, please contact your financial institution.<br /><br />If you have a technical issue with KONEK (e.g., payment timing out, error message, etc.) please contact your financial institution.'));
      this.updateChatbotState(handlePaymentsRefunds4);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handlePaymentsRefunds5 = () => {
      const handlePaymentsRefunds5 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. KONEK was developed in collaboration with Interac and participating Canadian financial institutions.  When you pay using KONEK, the service uses your financial institution’s secure authentication and payment consent protocols, and sensitive payment data like card or account numbers are replaced with randomized numbers through tokenization, so only the data necessary to complete the transaction is shared with the merchant. For more information, please visit the privacy statement at KONEK.ca'));
      this.updateChatbotState(handlePaymentsRefunds5);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };
    handlePaymentsRefunds6 = () => {
      const handlePaymentsRefunds6 = this.createChatBotMessage('KONEK is currently in beta testing by invitation only. KONEK was developed in collaboration with Interac and participating Canadian financial institutions.  When you pay using KONEK, the service uses your financial institution’s secure authentication and payment consent protocols, and sensitive payment data like card or account numbers are replaced with randomized   numbers through tokenization, so only the data necessary to complete the transaction is shared with the merchant.');
      this.updateChatbotState(handlePaymentsRefunds6);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };

    handlePaymentsRefunds7 = () => {
      const handlePaymentsRefunds7 = this.createChatBotMessage(parse('KONEK is currently in beta testing by invitation only. We work with stakeholders and security experts to maintain the ongoing security of our services, but there are actions that are important for you to take as well to help protect yourself against fraud such as creating strong passwords or using anti-virus software on all of your devices. You should also regularly check your account statements to verify that all transactions have been properly processed. If entries are not accurate – for example, there are missing or additional transactions – you should contact your financial institution.'));
      this.updateChatbotState(handlePaymentsRefunds7);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };

    handleRequestMoney2 = () => {
      const handleRequestMoney2 = this.createChatBotMessage(parse('Yes, you can opt-out of receiving requests from a particular requestor or of the service completely, by selecting the opt-out option on the fulfillment page. You can also opt-in to receive requests from a particular individual, or for the service, by managing your preference through your financial institution’s online banking or mobile banking application. Simply navigate to the <em>Interac</em> e-Transfer settings or by clicking here. Please note this experience will vary by participating financial institution.'));
      this.updateChatbotState(handleRequestMoney2);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    };

    handleFeedback = () => {
      const message = this.createChatBotMessage(
        'I\'m always looking for feedback. Let me know how you thought I did today.',
        {
          widget: 'Feedback',
        }
      );
  
      this.updateChatbotState(message);
    };
    handleLikedIt = () => {
      const message = this.createChatBotMessage(
        'Thanks for the positive feedback.',
      );
  
      this.updateChatbotState(message);
    };
    handleHatedIt = () => {
      const message = this.createChatBotMessage(
        'Sorry I wasn\'t helpful. I\'ll pass your feedback along so that I can improve.',
      );
  
      this.updateChatbotState(message);
    };
    handleSoSo = () => {
      const message = this.createChatBotMessage(
        'Sorry I wasn\'t helpful. I\'ll pass your feedback along so that I can improve.',
      );
  
      this.updateChatbotState(message);
    };
  
    updateChatbotState(message) {
      // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.
  
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    }
    contactUs() {
      const contactUs = this.createChatBotMessage(parse('Visit our <a href="https://www.interac.ca/en/contact-us/">contact us</a> page for details.'));
      this.updateChatbotState(contactUs);

      const moreQuestionsMessage = this.createChatBotMessage(parse('Is there anything else I can help with?'),
        {
          widget: 'MoreQuestions',
        }
      );
      this.updateChatbotState(moreQuestionsMessage);
    }
  }
  
  export default ActionProvider;