import React, { useState } from 'react';
import Chatbot from 'react-chatbot-kit'

import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';

function App() {
  const [showBot, toggleBot] = useState(false);
  
  const saveMessages = (messages) => {
    localStorage.setItem('chat_messages', JSON.stringify(messages));
  };

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  // const loadMessages = () => {
  //   const messages = JSON.parse(localStorage.getItem('chat_messages'));
  //   return messages;
  // };
  return (
    <div className={isActive ? 'ChatbotApp --active': 'ChatbotApp --disabled'} 
    onClick={toggleClass}>
      <header className='Chatbot-App-header'>
      {showBot && (
        <Chatbot config={config}
        actionProvider={ActionProvider}
        // messageHistory={loadMessages()}
        messageParser={MessageParser}
        saveMessages={saveMessages} />
        )}
      </header>
      <div className={isActive ? 'chatbotActive': 'chatbotDisabled'} 
      onClick={toggleClass}><button aria-label='chatbot-toggle' className="chatbotToggle" onClick={() => toggleBot((prev) => !prev)}></button></div>
    </div>
  );
}

export default App;
