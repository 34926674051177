import React from "react";

const Feedback = (props) => {
  const options = [
    {
      text: 'Liked It',
      handler: props.actionProvider.handleLikedIt,
      id: 1,
    },
    {
      text: 'Hated It',
      handler: props.actionProvider.handleHatedIt,
      id: 2 },
    {
      text: 'Okay, could be better.',
      handler: props.actionProvider.handleSoSo,
      id: 3
    },
  ];

  const optionsMarkup = options.map((option) => (
    <label key={option.id}>
    <input
      type="radio"
      className='learning-option-button'
      value={option.id}
      name="consumer"
      onClick={option.handler}
    />

      <span>{option.text}</span>
      </label>
    
  ));

  return <div className='learning-options-container'>{optionsMarkup}</div>;
};

export default Feedback;